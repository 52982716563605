













































import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  setup(_, { root }) {
    const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

    const form = ref<any>(null)

    const state = reactive({
      success: false,
      error: null as null | string,
      loading: false,
      isFormValid: true
    })

    const model = reactive({
      oldPassword: '',
      newPassword: '',
      newPassword2: ''
    })

    const rules = {
      oldPassword: [
        (v: string) => !!v || 'Podaj stare hasło',
        (v: string) => v !== model.newPassword || 'Wpisałeś takie samo stare i nowe hasło'
      ],
      newPassword: [
        (v: string) => !!v || 'Podaj nowe hasło',
        (v: string) => v !== model.oldPassword || 'Wpisałeś takie samo stare i nowe hasło',
        (v: string) => v === model.newPassword2 || 'Hasła nie są zgodne'
      ],
      newPassword2: [
        (v: string) => !!v || 'Potwierdź nowe hasło',
        (v: string) => v === model.newPassword || 'Hasła nie są zgodne'
      ]
    }

    const resetModel = async () => {
      model.oldPassword = ''
      model.newPassword = ''
      model.newPassword2 = ''

      await form?.value?.resetValidation()
    }

    const changePassword = () => {
      const data = {
        oldPassword: model.oldPassword,
        newPassword: model.newPassword
      }

      state.loading = true
      state.success = false
      state.error = null

      axiosInstance
        .post('auth/change-password', data)
        .then(() => {
          resetModel().finally(() => state.success = true)
        })
        .catch(error => {
          if (error.response.status == 406) {
            state.error = 'Podane hasło jest błędne'
          } else {
            state.error = 'Wystąpił błąd'
          }
        })
        .finally(() => state.loading = false)
    }

    const onSubmit = async () => {
      await form.value.validate()
      if (state.isFormValid) changePassword()
    }

    watch(() => model.newPassword, () => form?.value?.validate())
    watch(() => model.newPassword2, () => form?.value?.validate())

    watch(
      () => model,
      () => {
        form?.value?.validate()
        state.success = false
        state.error = null
      },
      { deep: true }
    )

    return { form, state, model, rules, onSubmit }
  }
})
